<template>
	<div class="data-content">
		<div class="top-box">
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="getDataList">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			 <el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				border
				style="width: 100%">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="eduPlanCode"
					width="110"
					show-overflow-tooltip
					label="教育计划编号">
				</el-table-column>
				<el-table-column
					prop="rangeName"
					show-overflow-tooltip
					label="地区">
				</el-table-column>
				<el-table-column
					prop="roomName"
					show-overflow-tooltip
					label="会场名称">
				</el-table-column>
				<el-table-column
					prop="trainName"
					show-overflow-tooltip
					label="培训地点">
				</el-table-column>
				<el-table-column
					prop="courseCode"
					show-overflow-tooltip
					label="课程编号">
				</el-table-column>
				<el-table-column
					prop="courseName"
					show-overflow-tooltip
					label="课程名称">
				</el-table-column>
				<el-table-column
					prop="areaName"
					show-overflow-tooltip
					label="培训期间">
					<template slot-scope="scope">
						{{scope.row.startTime.replace(new RegExp('-','g'),'/')}}~{{scope.row.endTime.replace(new RegExp('-','g'),'/')}}
					</template>
				</el-table-column>
				<el-table-column
					prop="enterMax"
					show-overflow-tooltip
					label="报名上限">
				</el-table-column>
				<el-table-column
					prop="enterMin"
					show-overflow-tooltip
					label="报名下限">
				</el-table-column>
				<el-table-column
					prop="enterStopTime"
					width="110"
					show-overflow-tooltip
					label="报名截止时间">
					<template slot-scope="scope">
						<span>{{scope.row.enterStopTime.replace(new RegExp('-','g'),'/')}}</span>
					</template>
				</el-table-column>
				<el-table-column
					label="操作"
					width="130">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleCheck(scope.row)" v-if="perms.includes('train:offline:viewMember')">查看报名人员</button>
						<button class="btn-blue" @click="handleSign(scope.row)" v-if="perms.includes('train:offline:addMember')">添加报名人员</button>
						<button class="btn-blue" @click="handleEditData('check',scope.row)">详细</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-data ref="editData" @getDataList="getDataList"></edit-data>
		<edit-sign ref="editSign"></edit-sign>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import EditSign from './components/edit-sign'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		EditSign,
		Pagination
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			pageNo: 1,
			pageSize: 20,
			total: 0,
			searchKey: '',
			dataList: [],
			visible: false,
			errorList: []
		}
	},
	created() {
		this.getDataList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 重置查询条件
		handleReset() {
			this.searchKey = '';
			this.pageNo = 1;
			this.getDataList();
		},
		// 获取列表数据
		getDataList() {
			let that = this;
			that.$request.post(
				"registerList",
				true,
				{
					publishFlag: 1,
					searchKey: that.searchKey,
					pageNo: that.pageNo,
					pageSize: that.pageSize
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 详细
		handleEditData(type,data) {
			this.$refs.editData.init(type,data);
		},
		// 报名
		handleSign(dt) {
			this.$refs.editSign.init(dt);
		},
		handleCheck(dt) {
			this.$router.push({
				name: 'signMember',
				query: {
					name: dt.courseName,
					id: dt.id,
					page: 'result'
				}
			})
		},
		handleSizeChange() {
			this.getDataList();
		},
		handleCurrentChange() {
			this.getDataList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getDataList();
		},
	}
}
</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>
<style>
	.el-message .user-message{
		margin: 15px;
	}
</style>
