import { render, staticRenderFns } from "./sign.vue?vue&type=template&id=5001c07c&scoped=true&"
import script from "./sign.vue?vue&type=script&lang=js&"
export * from "./sign.vue?vue&type=script&lang=js&"
import style0 from "./sign.vue?vue&type=style&index=0&id=5001c07c&lang=scss&scoped=true&"
import style1 from "./sign.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5001c07c",
  null
  
)

export default component.exports