<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="550px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-width="110px" ref="dataForm">
            <el-form-item label="姓名" prop="userId">
				<el-select v-model="dataForm.userId" placeholder="请选择学员" @change="handleStudent"
					filterable reserve-keyword remote clearable :remote-method="remoteMethod"
					:loading="loading" class="full-width">
					<el-option v-for="(item,i) in studentList" :key="i" :value="item.id" :label="item.fullName"></el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="学员编号" prop="userId">
                <el-input size="small" v-model="studentCode" placeholder="学员编号" :disabled="true"></el-input>
            </el-form-item>
			<el-form-item label="公司名称" prop="userId">
                <el-input size="small" v-model="companyName" placeholder="公司名称" :disabled="true"></el-input>
            </el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit" v-if="title != '查看培训'">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			title: '新增报名人员',
			visible: false,
			loading: false,
			studentList: [],
			studentCode: '',
			companyName: '',
			dataForm: {
				userId: '',
				trainId: ''
			},
			dataRule: {
				trainId: [
					{required: true,message: '线下培训课题ID', trigger: "blur"}
				],
				userId: [
					{required: true,message: '请选择学员', trigger: "change"}
				]
			}
		}
	},
	methods: {
		init(data) {
			this.visible = true;
			this.getAllUserList();
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				this.dataForm.trainId = data.id;
			})

		},
		// 获取所有用户数据
		getAllUserList(searchKey) {
			this.loading = true;
			let that = this
			that.$request.get(
				"getAllStudent",
				false, {
					searchKey: searchKey
				},
				function (r) {
					that.loading = false;
					if (r.code == "0") {
						that.studentList = r.data;
					} else {
						that.$message.error(r.msg)
					}
				}
			)
		},
		remoteMethod(query) {
			if (query == '') {
				return
			}
			this.getAllUserList(query);
		},
		// 学员下拉
		handleStudent() {
			this.studentList.forEach(item => {
				if(item.id == this.dataForm.userId){
					this.studentCode = item.stuId;
					this.companyName = item.agentName;
				}
			})
		},
		// 提交
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						'signOffline',
						true,
						that.dataForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 18px;
	.el-select,
	/deep/ .el-range-editor.el-input__inner,
	/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
		width: 100%;
	}
	/deep/ .el-input__inner{
		height: 32px;
		line-height: 32px;
	}
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	// padding: 0 !important;
	// line-height: 24px;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 20px 20px 40px !important;
}
</style>
